/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withAuth0 } from '@auth0/auth0-react'
import * as LoginPageActions from './actions'

import Button from '../../components/Button/Loadable'
import TextInput from '../../components/TextInput/Loadable'
import ModalOverlay from '../../components/ModalOverlay/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import { OfflineModal } from '../../components/OfflineModal'

import LoginPageStyled from './styledComponent'

import LoginLogoIMG from '../../asset/images/login/logo.svg'
import MESSAGE from './message'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, FRONTEND_ROUTES } from '../../utils'

const REQUIRE_PASSWORD_LENGTH = 6

export class LoginPage extends Component {
  state = {
    checkIsLogin: true,
    isInputValid: undefined,
    showAlertMessage: false,
    showLoading: false,
    showNoAccountModal: false,
    showOfflineModal: false,
    failureModalData: {
      show: false,
      title: '',
      message: '',
      onPrimaryButtonClick: undefined,
      primaryButtonText: ''
    }
  }

  async componentDidMount() {
    this.props.isLoggedIn()
  }

  async componentDidUpdate(prevProps, prevState) {
    this.handleFetchLogin(prevProps)
    this.handleFetchCheckLogin(prevProps)
    await this.handleFetchSSOLogin(prevProps)
    window.addEventListener('online', this.handleCloseOfflineModal)
    window.addEventListener('offline', this.handleShowOfflineModal)
  }

  handleShowOfflineModal = () => {
    this.setState({
      showOfflineModal: true
    })
  }

  handleCloseOfflineModal = () => {
    this.setState({
      showOfflineModal: false
    })
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleCloseOfflineModal)
    window.removeEventListener('offline', this.handleShowOfflineModal)
  }

  handleFetchSSOLogin = async (prevProps) => {
    const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, error } = this.props.auth0
    if (!prevProps.auth0.isAuthenticated && isAuthenticated) {
      const accessToken = await getAccessTokenSilently()
      const claims = await getIdTokenClaims()
      const ttl = claims.exp - claims.iat
      this.props.fetchUserSSO(accessToken, claims.__raw, ttl)
    }
    if (prevProps.loginPage.ssoLoginAPI.fetchStatus === FETCH_STATUS_IDLE && this.props.loginPage.ssoLoginAPI.fetchStatus === FETCH_STATUS_REQUEST) {
      this.showLoading()
    }
    if (
      prevProps.loginPage.ssoLoginAPI.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.loginPage.ssoLoginAPI.fetchStatus === FETCH_STATUS_SUCCESS &&
      isAuthenticated
    ) {
      this.handleLoginSuccess()
    }
    if (
      prevProps.loginPage.ssoLoginAPI.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.loginPage.ssoLoginAPI.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setState({
        showLoading: false,
        showNoAccountModal: true
      })
    }
    if (error) {
      this.showDefaultFailureModal()
    }
  }

  handleFetchLogin(prevProps) {
    if (
      !this.state.checkIsLogin &&
      prevProps.loginPage.loginAPI.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.loginPage.loginAPI.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.showLoading()
    }

    if (
      !this.state.checkIsLogin &&
      prevProps.loginPage.loginAPI.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.loginPage.loginAPI.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.handleLoginSuccess()
    }
    if (
      !this.state.checkIsLogin &&
      prevProps.loginPage.loginAPI.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.loginPage.loginAPI.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.handleLoginFailure(this.props.loginPage.loginAPI.error)
    }
  }

  handleFetchCheckLogin(prevProps) {
    if (
      this.state.checkIsLogin &&
      prevProps.loginPage.loginAPI.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.loginPage.loginAPI.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.redirectApplication()
    }
    if (
      this.state.checkIsLogin &&
      prevProps.loginPage.loginAPI.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.loginPage.loginAPI.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setState({
        checkIsLogin: false
      })
    }
  }

  showLoading() {
    this.setState({
      showLoading: true
    })
  }

  hideLoading() {
    this.setState({
      showLoading: false
    })
  }

  handleLoginSuccess() {
    this.props.history.push(FRONTEND_ROUTES.selectSiteRoute)
  }

  redirectApplication() {
    this.props.history.push(FRONTEND_ROUTES.selectSiteRoute)
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  handleLoginFailure() {
    this.setState({
      checkIsLogin: false,
      isInputValid: false,
      showAlertMessage: true
    })
    this.hideLoading()
    this.resetInput()
  }

  resetInput() {
    this.username.value = ''
    this.password.value = ''
  }

  getLoginLogo() {
    return (
      <div className="login-logo-container">
        <img id="logo-login" src={LoginLogoIMG} alt="" />
      </div>
    )
  }

  _isFormValid() {
    const regexEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i /*eslint-disable-line */
    const isInputValid = regexEmail.test(this.username.value.toLowerCase()) && this.password.value.length >= REQUIRE_PASSWORD_LENGTH
    this.setState({
      isInputValid,
      showAlertMessage: !isInputValid
    })
    return isInputValid
  }

  handleLoginClicked = () => {
    if (this._isFormValid()) {
      const data = {
        email: this.username.value,
        password: this.password.value
      }
      this.props.login(data)
    }
  }

  getAlertMessage() {
    const errorText = this.getMessage('error_text')
    let output = <div className="login-form-error-message-container">&nbsp;</div>
    if (this.state.showAlertMessage) {
      output = <div className="login-form-error-message-container">{errorText}</div>
    }
    return output
  }

  handleInputPressed = (e) => {
    if (e.key === 'Enter') {
      this.handleLoginClicked()
    }
  }

  getMessage = (message) => {
    const browserLang = navigator.language.toUpperCase()
    const lang = ['EN', 'TH'].includes(browserLang) ? browserLang : 'EN'
    return MESSAGE[lang][message]
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  getLoginForm() {
    return (
      <div className="login-form-container">
        <div className="login-form-row-container">
          <TextInput
            type="text"
            label={this.getMessage('username_label')}
            id="login-email-input"
            placeHolder={this.getMessage('username_placeholder')}
            passRef={(ref) => (this.username = ref)}
            errorText={this.state.isInputValid === false ? ' ' : ''}
            onKeyPress={this.handleInputPressed}
          />
        </div>
        <div className="login-form-row-container">
          <TextInput
            type="password"
            label={this.getMessage('password_label')}
            id="login-pass-input"
            placeHolder={this.getMessage('password_placeholder')}
            passRef={(ref) => (this.password = ref)}
            errorText={this.state.isInputValid === false ? ' ' : ''}
            onKeyPress={this.handleInputPressed}
          />
        </div>
        {this.getAlertMessage()}
        <div className="login-form-btn-container">
          <Button id="login-btn" className="login-btn" onClick={this.handleLoginClicked} text={this.getMessage('sign_in_button')} />
        </div>
      </div>
    )
  }

  getLoading() {
    let output = null
    if (this.state.showLoading || this.props.auth0.isLoading) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }

  handleCloseNoAccountModal = () => {
    this.props.auth0.logout({ returnTo: `${window.location.origin}/login` })
    this.setState({
      showNoAccountModal: false
    })
  }

  getNoAccoutModal = () => {
    let output = null
    if (this.state.showNoAccountModal) {
      output = (
        <ModalNotification
          className={'no-account-modal'}
          title={this.getMessage('sso_sign_in_failed_modal_title')}
          message={this.getMessage('sso_sign_in_failed_modal_message')}
          onPrimaryButtonClick={this.handleCloseNoAccountModal}
          primaryButtonText={this.getMessage('ok_btn')}
        />
      )
    }
    return output
  }

  getOfflineModal = () => {
    const className = this.state.showOfflineModal ? 'offline-modal' : 'offline-modal hide'
    return (
      <OfflineModal
        className={className}
        title={this.getMessage('offline_modal_title')}
        message={this.getMessage('offline_modal_message')}
        onPrimaryButtonClick={this.handleCloseOfflineModal}
        primaryButtonText={this.getMessage('ok_btn')}
      />
    )
  }

  render() {
    return (
      <LoginPageStyled>
        <div className="login-container">
          {this.getLoginLogo()}
          {this.getLoginForm()}
        </div>
        {this.getLoading()}
        {this.getNoAccoutModal()}
        {this.getOfflineModal()}
        {this.getFailureModal()}
      </LoginPageStyled>
    )
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loginPage: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    loginPage: state.loginPage
  }
}

/* istanbul ignore next */
const mapDispatchToPtops = (dispatch) => {
  return bindActionCreators(Object.assign({}, LoginPageActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToPtops)(withAuth0(LoginPage))
