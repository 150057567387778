import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.darkBackground};
  .login-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .login-logo-container {
      width: 399px;
      height: 109px;
      margin-bottom: 124px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .login-form-container {
      width: 100%;
      .login-form-row-container {
        width: 100%;
        margin-bottom: 30px;
      }
      .login-form-error-message-container {
        width: 100%;
        height: 20px;
        text-align: center;
        margin-bottom: 23px;
        font-size: 12px;
        color: ${(props) => props.theme.orangeFont};
      }
      .login-form-btn-container {
        width: 100%;
        .login-btn {
          width: 100%;
          height: 50px;
        }
        text-align: center;
        p {
          font-family: 'Prompt', sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.25;
          color: #fff;
          margin: 34px auto 16px;
        }
      }
    }
  }
  .offline-modal {
    &.hide {
      display: none;
    }
  }
`
