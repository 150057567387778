export default {
  EN: {
    username_label: 'USERNAME',
    username_placeholder: 'Enter Username',
    password_label: 'PASSWORD',
    password_placeholder: 'Enter Password',
    sign_in_button: 'Sign In',
    error_text: 'Email or Password is incorrect',
    sso_sign_in_failed_modal_title: "Can't sign in with Farmlab account",
    sso_sign_in_failed_modal_message: `The email you entered is not registered 
		on Farmlab account. Please contact your 
		CPF Administrator.`,
    ok_btn: 'OK',
    offline_modal_title: 'No internet connection',
    offline_modal_message: 'Please check your internet connection and try again.',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    username_label: 'ชื่อผู้ใช้',
    username_placeholder: 'ใส่ชื่อผู้ใช้',
    password_label: 'รหัสผ่าน',
    password_placeholder: 'ใส่รหัสผ่าน',
    sign_in_button: 'เข้าสู่ระบบ',
    error_text: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
    sso_sign_in_failed_modal_title: 'ไม่สามารถเข้าสู่บัญชี Farmlab ได้',
    sso_sign_in_failed_modal_message: `อีเมลของคุณยังไม่ได้ลงทะเบียนในบัญชี Farmlab
    กรุณาติดต่อแอดมิน CPF`,
    ok_btn: 'ตกลง',
    offline_modal_title: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
    offline_modal_message: `กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ
    แล้วลองอีกครั้ง`,
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}
